import { isAfter } from 'date-fns';

import EventTicketsStatus from '@/components/Event/EventBuyTicketsContainer/EventTicketsStatus';
import TicketType from '@/components/Pages/TicketSelection/TicketType';

export const wait = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export const isServer = typeof window === `undefined`;

export const splitAddress = (address: string) => {
  const search = address.search(/,.*$/);

  return [
    address.slice(0, search + 1).trim(),
    address.slice(search + 1, address.length).trim(),
  ];
};

export const extractCityAndState = (
  address: string,
): [string, string] | null => {
  const regex = /\s+?([^,]+),\s+?([^,]+?)\s+?\d+$/;

  const executed = regex.exec(address);

  if (executed) {
    const [, city, state] = executed;

    return [city, state];
  } else {
    return null;
  }
};

const now = new Date();

export function getStatus({
  ticketTypes,
  isCancelled,
  isEnded,
}: {
  ticketTypes: TicketType[];
  isCancelled: boolean;
  isEnded: boolean;
}): EventTicketsStatus {
  if (isCancelled) {
    return `cancelled`;
  }
  if (isEnded) {
    return `ended`;
  }
  if (ticketTypes.length === 0) {
    return `no-tickets`;
  }

  if (ticketTypes.filter((it) => isAfter(now, it.saleStartsAt)).length === 0) {
    return `unavailable`;
  }

  if (
    ticketTypes.filter(
      (it) => isAfter(it.saleEndsAt, now) && it.ticketLeftCount > 0,
    ).length === 0
  ) {
    return `off-sale`;
  }

  return `available`;
}

export function download(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement(`a`);

  a.style.display = `none`;
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
