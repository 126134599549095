import { captureException } from '@sentry/nextjs';
import Image from 'next/image';
import { FC, ReactNode, useEffect } from 'react';

import dead from '@/assets/dead.svg';
import internalServerError from '@/components/Pages/InternalServerError/assets/internalServerError.svg';
import useViewport from '@/hooks/useViewport';
import { isServer } from '@/utils';

export type CookieDisabledProps = {
  children?: ReactNode;
};

const DetectCookieDisabled: FC<CookieDisabledProps> = (
  props: CookieDisabledProps,
) => {
  const { children } = props;
  const viewport = useViewport();

  useEffect(() => {
    if (isServer || window?.navigator?.cookieEnabled) {
      return;
    }
    captureException(new Error(`Browser does not have cookies enabled.`));
  }, []);

  if (isServer || window?.navigator?.cookieEnabled) {
    return <>{children}</>;
  }

  switch (viewport) {
    case `mobile`: {
      return (
        <div
          className={`relative flex min-h-screen flex-col justify-between px-[24px] pb-[40px] pt-[130px]`}
        >
          <div className={`mb-[40px] flex w-full flex-col items-center`}>
            <Image width={140} height={140} src={dead} alt="dead" />
            <p
              className={`mt-[8px] text-center font-roboto text-h2 font-bold text-gray-600`}
            >
              {`Your browser does not have cookies enabled.`}
            </p>
            <p
              className={`mt-[8px] text-center font-roboto text-b2 font-normal text-gray-200`}
            >
              {`Please enable cookies in your browser and try again. See your browser's Help for information about enabling cookies.`}
            </p>
          </div>
        </div>
      );
    }
    case `tablet`:
    case `desktop`: {
      return (
        <div
          className={`absolute flex min-h-screen w-full items-center justify-center`}
        >
          <div className={`flex flex-col`}>
            <Image
              width={498}
              height={154}
              src={internalServerError}
              alt="internalServerError"
            />
            <p
              className={`mt-[8px] text-center font-roboto text-h2 font-bold text-gray-600`}
            >
              {`Your browser does not have cookies enabled.`}
            </p>
            <p
              className={`mt-[8px] text-center font-roboto text-b2 font-normal text-gray-200`}
            >
              {`Please enable cookies in your browser and try again. See your browser's Help for information about enabling cookies.`}
            </p>
          </div>
        </div>
      );
    }
  }
};

export default DetectCookieDisabled;
