import Image from 'next/image';
import Link from 'next/link';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import internalServerError from './assets/internalServerError.svg';

import dead from '@/assets/dead.svg';
import PrimaryButton from '@/components/Module/PrimaryButton';
import useViewport from '@/hooks/useViewport';

export type InternalServerErrorProps = {
  error?: Error;
};

const MobileInternalServerError = (props: InternalServerErrorProps) => {
  const { error } = props;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error && error.message) {
      enqueueSnackbar(error.message, { variant: `error` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`relative flex min-h-screen flex-col justify-between px-[24px] pb-[40px] pt-[130px]`}
    >
      <div className={`mb-[40px] flex w-full flex-col items-center`}>
        <Image width={140} height={140} src={dead} alt="dead" />
        <p
          className={`mt-[8px] text-center font-roboto text-h2 font-bold text-gray-600`}
        >
          Something went wrong..
        </p>
        <p
          className={`mt-[8px] text-center font-roboto text-b2 font-normal text-gray-200`}
        >
          Please try again in a few minutes
        </p>
      </div>

      <Link href={`/`}>
        <a className={`contents`}>
          <PrimaryButton>Home</PrimaryButton>
        </a>
      </Link>
    </div>
  );
};

const DesktopInternalServerError = (props: InternalServerErrorProps) => {
  const { error } = props;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error && error.message) {
      enqueueSnackbar(error.message, { variant: `error` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`absolute flex min-h-screen w-full items-center justify-center`}
    >
      <div className={`flex flex-col`}>
        <Image
          width={498}
          height={154}
          src={internalServerError}
          alt="internalServerError"
        />
        <p
          className={`mt-[8px] text-center font-roboto text-h2 font-bold text-gray-600`}
        >
          Something went wrong..
        </p>
        <p
          className={`mt-[8px] text-center font-roboto text-b2 font-normal text-gray-200`}
        >
          Please try again in a few minutes
        </p>
        <div className={`mt-[72px]`}>
          <Link href={`/`}>
            <a className={`contents`}>
              <PrimaryButton>Home</PrimaryButton>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const InternalServerError = (props: InternalServerErrorProps) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileInternalServerError {...props} />;
  }
  if (viewport == `desktop` || viewport == `tablet`) {
    return <DesktopInternalServerError {...props} />;
  }

  return null;
};

export default InternalServerError;
