export const NEXT_PUBLIC_AUTH0_DOMAIN = process.env
  .NEXT_PUBLIC_AUTH0_DOMAIN as string;
export const NEXT_PUBLIC_AUTH0_CLIENT_ID = process.env
  .NEXT_PUBLIC_AUTH0_CLIENT_ID as string;
export const NEXT_PUBLIC_AUTH0_AUDIENCE = process.env
  .NEXT_PUBLIC_AUTH0_AUDIENCE as string;
export const NEXT_PUBLIC_SENTRY_DSN = process.env
  .NEXT_PUBLIC_SENTRY_DSN as string;
export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY = process.env
  .NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string;
export const NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE = process.env
  .NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE
  ? Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE)
  : 0.01;
export const NEXT_PUBLIC_GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string;
export const NEXT_PUBLIC_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL as string;
export const NEXT_PUBLIC_SENTRY_ENV = process.env
  .NEXT_PUBLIC_SENTRY_ENV as string;
export const NEXT_PUBLIC_GRAPHQL_ENDPOINT = process.env
  .NEXT_PUBLIC_GRAPHQL_ENDPOINT as string;
