import { User as Auth0User } from '@auth0/auth0-react';

export type AuthType = `email` | `google` | `unknown`;

export function getAuthType(auth0user: Auth0User) {
  return auth0user.sub?.startsWith(`auth0`, 0)
    ? `email`
    : auth0user.sub?.startsWith(`google-oauth2`, 0)
    ? `google`
    : `unknown`;
}

export type User = {
  id: string;
  role: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailAddress: string;
  phoneNumber: string;
  signedUpAt: number;
  intercomHmac: string;
  consentedToMarketing: boolean;
};
