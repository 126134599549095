import { useAuth0, User as Auth0User } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { GTM_EVENTS } from '@/constants';
import { useMyInfoQuery } from '@/generated/graphql';
import { AuthType, getAuthType, User } from '@/types/auth';

export type AuthModel =
  | {
      state: 'logout';
      auth0user?: undefined;
      type?: undefined;
    }
  | {
      state: 'loading';
      auth0user?: undefined;
      type?: undefined;
    }
  | {
      state: 'error';
      auth0user?: undefined;
      type?: undefined;
      error: Error;
    }
  | {
      state: 'email-pending';
      auth0user: Auth0User;
      type: AuthType;
    }
  | {
      state: 'ready-to-signup';
      auth0user: Auth0User;
      type: AuthType;
    }
  | {
      state: 'profile-created';
      auth0user: Auth0User;
      type: AuthType;
      user: User;
    };

export const AuthContext = React.createContext<{
  authModel: AuthModel;
  update: () => unknown;
}>({
  authModel: {
    state: `logout`,
  },
  update: () => null,
});

export type AuthContextProviderProps = {
  children?: ReactNode;
};

export function AuthContextProvider(props: AuthContextProviderProps) {
  const { children } = props;
  const router = useRouter();
  const { isLoading, error, user } = useAuth0();
  const [authModel, setAuthModel] = useState<AuthModel>({ state: `logout` });
  const [value, setValue] = useState(0);
  const update = () => setValue((v) => v + 1);
  const { refetch } = useMyInfoQuery();

  const fetchAndUpdateAuthModel = useCallback(async () => {
    if (error) {
      return setAuthModel({
        state: `error`,
        error: error,
      });
    }
    if (isLoading) {
      return setAuthModel({
        state: `loading`,
      });
    }
    const auth0user = user;

    if (!auth0user) {
      return setAuthModel({
        state: `logout`,
      });
    }
    const authType = getAuthType(auth0user);

    /*
    if (!auth0user.email_verified) {
      return setAuthModel({
        state: `email-pending`,
        auth0user: auth0user,
        type: authType,
      });
    }
     */

    try {
      const { data, error } = await refetch();

      if (error) {
        throw Error(error.message);
      }

      if (!data.me) {
        throw Error(`Unauthorized`);
      }

      TagManager.dataLayer({
        dataLayer: {
          user_id: data.me.id,
          user_email: data.me.emailAddress,
          user_last_name: data.me.firstName,
          user_first_name: data.me.lastName,
          user_role: data.me.role,
          user_date_of_birth: data.me.dateOfBirth,
          user_phone_number: data.me.phoneNumber,
          user_intercom_hmac: data.me.intercomHmac,
          event: GTM_EVENTS.user_sign_in,
        },
      });

      Sentry.setUser({ id: data.me.id, email: data.me.emailAddress });

      return setAuthModel({
        state: `profile-created`,
        auth0user: auth0user,
        type: authType,
        user: data.me,
      });
    } catch (error) {
      if (error instanceof Error && error.message.includes(`Unauthorized`)) {
        return setAuthModel({
          state: `ready-to-signup`,
          auth0user: auth0user,
          type: authType,
        });
      } else {
        return setAuthModel({
          state: `error`,
          error: error as Error,
        });
      }
    }
  }, [error, isLoading, refetch, user]);

  useEffect(() => {
    if (authModel.state === `profile-created`) {
      return;
    }
    fetchAndUpdateAuthModel();
  }, [authModel.state, fetchAndUpdateAuthModel, router.pathname]);

  useEffect(() => {
    if (value > 0) {
      fetchAndUpdateAuthModel();
    }
  }, [fetchAndUpdateAuthModel, value]);

  return (
    <AuthContext.Provider value={{ authModel, update: update }}>
      {children}
    </AuthContext.Provider>
  );
}
