import '@/styles/globals.css';
import '@/styles/swiper.css';
import '@/styles/phonenumber-input.css';
import '@/styles/spinner.css';
import '@/styles/share-api-polyfill.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Auth0Provider } from '@auth0/auth0-react';
import { captureException } from '@sentry/nextjs';
import { Analytics } from '@vercel/analytics/react';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { AppContextType } from 'next/dist/shared/lib/utils';
import { useRouter } from 'next/router';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';

import DetectCookieDisabled from '@/components/Pages/DetectCookieDisabled';
import InternalServerError from '@/components/Pages/InternalServerError';
import AgentContext from '@/contexts/AgentContext';
import { AuthContextProvider } from '@/contexts/AuthContext';
import {
  NEXT_PUBLIC_AUTH0_AUDIENCE,
  NEXT_PUBLIC_AUTH0_CLIENT_ID,
  NEXT_PUBLIC_AUTH0_DOMAIN,
  NEXT_PUBLIC_GTM_ID,
} from '@/env';
import ApolloProviderWithAuth0 from '@/Provider/ApolloProviderWithAuth0';
import { isServer } from '@/utils';

if (!isServer) {
  require(`share-api-polyfill`);
}

if (global?.window) {
  TagManager.initialize({
    gtmId: NEXT_PUBLIC_GTM_ID,
  });
}

function MyApp({
  Component,
  pageProps,
  userAgent,
}: AppProps & { userAgent: string }) {
  console.log(pageProps);
  const router = useRouter();

  return (
    <SnackbarProvider maxSnack={3}>
      <ErrorBoundary
        FallbackComponent={InternalServerError}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
        onError={(error) => {
          captureException(error);
        }}
      >
        <DetectCookieDisabled>
          <Auth0Provider
            domain={NEXT_PUBLIC_AUTH0_DOMAIN}
            clientId={NEXT_PUBLIC_AUTH0_CLIENT_ID}
            redirectUri={global?.window?.location?.origin}
            onRedirectCallback={(appState) => {
              if (appState) {
                if (appState.returnTo) {
                  return router.replace(appState.returnTo);
                } else if (appState.callbackUri) {
                  return router.replace(appState.callbackUri);
                }
              }
            }}
            cacheLocation={`localstorage`}
            audience={NEXT_PUBLIC_AUTH0_AUDIENCE}
            scope="openid profile email"
          >
            <ApolloProviderWithAuth0>
              <AuthContextProvider>
                <AgentContext.Provider value={{ userAgent }}>
                  <DefaultSeo
                    title={`Someday - Shows You'll Look Back On`}
                    description={`Find tickets to curated events at independent clubs and rooms near you. Cut through the noise with easy check-out and check-in.`}
                    openGraph={{
                      title: `Someday - Shows You'll Look Back On`,
                      description: `Find tickets to curated events at independent clubs and rooms near you. Cut through the noise with easy check-out and check-in.`,
                      images: [
                        {
                          url: `https://ucarecdn.com/c5bd4f31-8c32-4770-b1a4-c63c1d366df7/`,
                        },
                      ],
                    }}
                    additionalLinkTags={[
                      {
                        rel: `shortcut icon`,
                        href: `/favicon.svg`,
                      },
                    ]}
                  />

                  <Component {...pageProps} />
                  <Analytics />
                </AgentContext.Provider>
              </AuthContextProvider>
            </ApolloProviderWithAuth0>
          </Auth0Provider>
        </DetectCookieDisabled>
      </ErrorBoundary>
    </SnackbarProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContextType) => {
  const req = appContext?.ctx?.req;
  const userAgent = req ? req.headers[`user-agent`] : navigator.userAgent;

  return { userAgent };
};

export default MyApp;
