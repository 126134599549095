import { ReactNode } from 'react';

export type PrimaryButtonProps = {
  children?: ReactNode;
  disabled?: boolean;
  destructive?: boolean;
  onClick?: () => unknown;
  py?: number;
  px?: number;
};

const PrimaryButton = (props: PrimaryButtonProps) => {
  const { children, disabled, destructive, onClick, py = 19, px } = props;

  if (disabled) {
    return (
      <button
        className={`w-full rounded-[12px] bg-gray-50 font-roboto text-h4 font-bold text-gray-300`}
        disabled={true}
        style={{
          paddingTop: `${py}px`,
          paddingBottom: `${py}px`,
          paddingLeft: px ? `${px}px` : undefined,
          paddingRight: px ? `${px}px` : undefined,
        }}
      >
        {children}
      </button>
    );
  }

  if (destructive) {
    return (
      <button
        className={`w-full rounded-[12px] bg-danger-red-100 font-roboto text-h4 font-bold text-gray-600 sm:hover:bg-danger-red-300`}
        onClick={onClick}
        style={{
          paddingTop: `${py}px`,
          paddingBottom: `${py}px`,
          paddingLeft: px ? `${px}px` : undefined,
          paddingRight: px ? `${px}px` : undefined,
        }}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      className={`w-full rounded-[12px] bg-green-100 font-roboto text-h4 font-bold text-gray-0 sm:hover:bg-green-200`}
      onClick={onClick}
      style={{
        paddingTop: `${py}px`,
        paddingBottom: `${py}px`,
        paddingLeft: px ? `${px}px` : undefined,
        paddingRight: px ? `${px}px` : undefined,
      }}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
