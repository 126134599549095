import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { setContext } from '@apollo/link-context';
import { useAuth0 } from '@auth0/auth0-react';
import { SentryLink } from 'apollo-link-sentry';
import { ReactNode, useRef } from 'react';

import { NEXT_PUBLIC_GRAPHQL_ENDPOINT } from '@/env';
import { isServer } from '@/utils';

type ApolloProviderWithAuth0Props = {
  children?: ReactNode;
};

const ApolloProviderWithAuth0 = (props: ApolloProviderWithAuth0Props) => {
  const { children } = props;
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = new HttpLink({
    uri: isServer ? NEXT_PUBLIC_GRAPHQL_ENDPOINT : `/proxy/graphql`,
  });

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;

    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      console.log(error);
    }

    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const client = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

  if (!client.current) {
    client.current = new ApolloClient({
      link: ApolloLink.from([
        new SentryLink({
          attachBreadcrumbs: {
            includeQuery: true,
            includeVariables: true,
            includeFetchResult: true,
            includeError: true,
            includeCache: true,
          },
        }),
        authLink.concat(httpLink),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: `no-cache`,
          errorPolicy: `all`,
        },
      },
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default ApolloProviderWithAuth0;
