var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1c9009ab37540fe909d9a280452e712aa8966d7a"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import {
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENV,
  NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
} from '@/env';

if (NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: NEXT_PUBLIC_SENTRY_DSN,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: NEXT_PUBLIC_SENTRY_ENV,
    tracesSampleRate: NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}
