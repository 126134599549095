import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An arbitrary precision signed decimal */
  BigDecimal: any;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  /** A 64-bit signed integer */
  Long: any;
};

export type AddPromoCodeToEventInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  eventId: Scalars['ID'];
  ticketTypeIds: Array<Scalars['Long']>;
  validFrom: Scalars['String'];
  validThrough: Scalars['String'];
};

export type AddTicketPoolToEventInput = {
  eventId: Scalars['ID'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ticketCount: Scalars['Int'];
  ticketTypes: Array<AddTicketTypeToEventInput>;
};

export type AddTicketTypeToEventInput = {
  basePrice: MoneyInput;
  eventId: Scalars['ID'];
  fee?: InputMaybe<MoneyInput>;
  helperText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  promoCodes?: InputMaybe<Array<Scalars['String']>>;
  saleEndsAt: Scalars['String'];
  saleStartsAt: Scalars['String'];
  ticketCount?: InputMaybe<Scalars['Int']>;
  ticketPoolId?: InputMaybe<Scalars['String']>;
};

export type AdminQuery = {
  __typename?: 'AdminQuery';
  allTicketTypes?: Maybe<Array<TicketType>>;
  promoCodes?: Maybe<Array<PromoCode>>;
  sales?: Maybe<EventSalesData>;
  taxRates?: Maybe<TaxRateConnection>;
  users?: Maybe<UserConnection>;
  venueCode?: Maybe<Scalars['String']>;
};


export type AdminQueryAllTicketTypesArgs = {
  eventId: Scalars['ID'];
};


export type AdminQueryPromoCodesArgs = {
  eventId: Scalars['ID'];
};


export type AdminQuerySalesArgs = {
  eventId: Scalars['ID'];
};


export type AdminQueryTaxRatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  rate?: InputMaybe<Scalars['BigDecimal']>;
  sort?: InputMaybe<Sort>;
};


export type AdminQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  lastName?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};


export type AdminQueryVenueCodeArgs = {
  venueId: Scalars['ID'];
};

export type AgreeToTermsInput = {
  version: Scalars['Int'];
};

export type CancelEmbeddedCheckoutInput = {
  checkoutId: Scalars['ID'];
};

export type CancelEventInput = {
  eventId: Scalars['ID'];
};

export type CancelPurchaseInput = {
  purchaseId: Scalars['ID'];
  reason: Scalars['String'];
};

export type CompleteEmbeddedPaymentInput = {
  checkoutId: Scalars['ID'];
  paymentMethodId: Scalars['String'];
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type ConsentToMarketingInput = {
  consented: Scalars['Boolean'];
};

export type CreateEmbeddedCheckoutInput = {
  eventId: Scalars['ID'];
  lineItems: Array<LineItemInput>;
};

export type CreateEventInput = {
  audienceAllowedMinimumAge: Scalars['Int'];
  description: Scalars['String'];
  endDateTime: Scalars['String'];
  featuredLevel?: InputMaybe<Scalars['Int']>;
  imgUrl: Scalars['String'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  lineup: LineupInput;
  stageId: Scalars['ID'];
  startDateTime: Scalars['String'];
  ticketPools?: InputMaybe<Array<CreateTicketPoolInput>>;
  ticketTypes?: InputMaybe<Array<CreateTicketTypeInput>>;
  title: Scalars['String'];
};

export type CreatePerformerInput = {
  description: Scalars['String'];
  facebookUrl?: InputMaybe<Scalars['String']>;
  imgUrl: Scalars['String'];
  instagramUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  spotifyUrl?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CreateSetupIntentResult = {
  __typename?: 'CreateSetupIntentResult';
  clientSecret: Scalars['String'];
};

export type CreateStageInput = {
  activated: Scalars['Boolean'];
  feeStrategy: FeeStrategyInput;
  name: Scalars['String'];
  venueId: Scalars['ID'];
};

export type CreateTaxRateInput = {
  name: Scalars['String'];
  rate: Scalars['String'];
};

export type CreateTicketPoolInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ticketCount: Scalars['Int'];
  ticketTypes: Array<CreateTicketTypeInput>;
};

export type CreateTicketTypeInput = {
  basePrice: MoneyInput;
  fee?: InputMaybe<MoneyInput>;
  helperText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  saleEndsAt: Scalars['String'];
  saleStartsAt: Scalars['String'];
  ticketCount?: InputMaybe<Scalars['Int']>;
};

export type CreateVenueInput = {
  activated: Scalars['Boolean'];
  address: Scalars['String'];
  facebookUrl?: InputMaybe<Scalars['String']>;
  imgUrl: Scalars['String'];
  instagramUrl?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  mapUrl: Scalars['String'];
  name: Scalars['String'];
  taxRateId: Scalars['ID'];
  timezone: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type DeleteAccountInput = {
  userId: Scalars['ID'];
};

export type DollarBasedFeeStrategy = {
  __typename?: 'DollarBasedFeeStrategy';
  dollars: Money;
};

export type DollarPlusPercentBasedFeeStrategy = {
  __typename?: 'DollarPlusPercentBasedFeeStrategy';
  dollars: Money;
  percent: Scalars['BigDecimal'];
};

export type Edge = {
  cursor: Scalars['String'];
  node: Node;
};

export type EmbeddedCheckout = {
  __typename?: 'EmbeddedCheckout';
  createdAt: Scalars['DateTime'];
  event: Event;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  lineItems: Array<EmbeddedCheckoutLineItem>;
  state: Scalars['String'];
  taxRate: TaxRate;
  taxRateId?: Maybe<Scalars['ID']>;
  url: Scalars['String'];
  user: User;
  userId: Scalars['ID'];
};

export type EmbeddedCheckoutLineItem = {
  __typename?: 'EmbeddedCheckoutLineItem';
  description: Scalars['String'];
  eventId: Scalars['ID'];
  id: Scalars['String'];
  price: Price;
  quantity: Scalars['Long'];
  ticketType: TicketType;
  ticketTypeId: Scalars['String'];
};

export type Event = Node & {
  __typename?: 'Event';
  audienceMinimumAge: Scalars['Int'];
  description: Scalars['String'];
  endDateTime: Scalars['DateTime'];
  featuredLevel: Scalars['Int'];
  id: Scalars['ID'];
  imgUrl: Scalars['String'];
  isCancelled: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  lineup: Lineup;
  stage: Stage;
  stageId: Scalars['ID'];
  startDateTime: Scalars['DateTime'];
  ticketPools: Array<TicketPool>;
  ticketTypes: Array<TicketType>;
  timezoneOffset: Scalars['String'];
  title: Scalars['String'];
  venue: Venue;
  venueId: Scalars['ID'];
};

export type EventConnection = Connection & {
  __typename?: 'EventConnection';
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
};

export type EventEdge = Edge & {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

export type EventSalesData = {
  __typename?: 'EventSalesData';
  items: Array<EventSalesItem>;
};

export type EventSalesItem = {
  __typename?: 'EventSalesItem';
  consentedToMarketing: Scalars['Boolean'];
  emailAddress: Scalars['String'];
  faceValue: Money;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  paidFee: Money;
  paidTax: Money;
  paidTotal: Money;
  ticketId: Scalars['String'];
  ticketTypeId: Scalars['String'];
  ticketTypeName: Scalars['String'];
};

export type FeaturedQuery = {
  __typename?: 'FeaturedQuery';
  events?: Maybe<Array<Event>>;
};


export type FeaturedQueryEventsArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type FeeStrategy = DollarBasedFeeStrategy | DollarPlusPercentBasedFeeStrategy | PercentBasedFeeStrategy;

export type FeeStrategyInput = {
  dollars?: InputMaybe<MoneyInput>;
  percent?: InputMaybe<Scalars['String']>;
  type: FeeStrategyType;
};

export enum FeeStrategyType {
  Dollars = 'DOLLARS',
  DollarsPlusPercent = 'DOLLARS_PLUS_PERCENT',
  Percent = 'PERCENT'
}

export type LineItemInput = {
  purchaseCount: Scalars['Long'];
  ticketTypeId: Scalars['String'];
};

export type Lineup = {
  __typename?: 'Lineup';
  count: Scalars['Int'];
  entries: Array<LineupEntry>;
};

export type LineupEntry = {
  __typename?: 'LineupEntry';
  id: Scalars['String'];
  order: Scalars['Int'];
  performer: Performer;
  performerId: Scalars['ID'];
};

export type LineupEntryInput = {
  order: Scalars['Int'];
  performerId: Scalars['ID'];
};

export type LineupInput = {
  entries: Array<LineupEntryInput>;
};

export type Location = {
  __typename?: 'Location';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type MoneyInput = {
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add promo code to event */
  addPromoCodeToEvent: MutationResult;
  /** Add ticket pool to event */
  addTicketPoolToEvent: MutationResult;
  /** Add ticket type to event */
  addTicketTypeToEvent: MutationResult;
  /** Agree to terms of service */
  agreeToTerms: MutationResult;
  /** Cancel and expire embedded checkout */
  cancelEmbeddedCheckout: MutationResult;
  /** Cancel event */
  cancelEvent: MutationResult;
  /** Cancel purchase */
  cancelPurchase: MutationResult;
  completeEmbeddedPurchase: MutationResult;
  /** Consent to marketing */
  consentToMarketing: MutationResult;
  createEmbeddedCheckout: EmbeddedCheckout;
  /** Create event */
  createEvent: MutationResult;
  createPerformer: MutationResult;
  createSetupIntent: CreateSetupIntentResult;
  /** Create stage */
  createStage: MutationResult;
  createStripeCustomers: MutationResult;
  /** Create tax rate */
  createTaxRate: MutationResult;
  /** Create venue */
  createVenue: MutationResult;
  /** Account Deletion */
  deleteAccount: MutationResult;
  /** Refund for purchase items */
  refundForPurchaseItems: MutationResult;
  /** Request refund for purchase */
  requestRefundForPurchase: MutationResult;
  /** Resend email verification */
  resendEmailVerification: MutationResult;
  /** Resend purchase confirmation email */
  resendPurchaseConfirmationEmail: MutationResult;
  /** Send email to users */
  sendEmailToUsers: MutationResult;
  /** Sign up */
  signUp: MutationResult;
  /** Update event data */
  updateEvent: MutationResult;
  updatePerformer: MutationResult;
  /** Update promo code */
  updatePromoCode: MutationResult;
  /** Update stage data */
  updateStage: MutationResult;
  /** Update ticket pool */
  updateTicketPool: MutationResult;
  /** Update ticket type */
  updateTicketType: MutationResult;
  /** Update user's profile */
  updateUserProfile: MutationResult;
  /** Update venue data */
  updateVenue: MutationResult;
};


export type MutationAddPromoCodeToEventArgs = {
  input: AddPromoCodeToEventInput;
};


export type MutationAddTicketPoolToEventArgs = {
  input: AddTicketPoolToEventInput;
};


export type MutationAddTicketTypeToEventArgs = {
  input: AddTicketTypeToEventInput;
};


export type MutationAgreeToTermsArgs = {
  input: AgreeToTermsInput;
};


export type MutationCancelEmbeddedCheckoutArgs = {
  input: CancelEmbeddedCheckoutInput;
};


export type MutationCancelEventArgs = {
  input: CancelEventInput;
};


export type MutationCancelPurchaseArgs = {
  input: CancelPurchaseInput;
};


export type MutationCompleteEmbeddedPurchaseArgs = {
  input: CompleteEmbeddedPaymentInput;
};


export type MutationConsentToMarketingArgs = {
  input: ConsentToMarketingInput;
};


export type MutationCreateEmbeddedCheckoutArgs = {
  input: CreateEmbeddedCheckoutInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreatePerformerArgs = {
  input: CreatePerformerInput;
};


export type MutationCreateStageArgs = {
  input: CreateStageInput;
};


export type MutationCreateTaxRateArgs = {
  input: CreateTaxRateInput;
};


export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationRefundForPurchaseItemsArgs = {
  input: RefundForPurchaseItemsMutationInput;
};


export type MutationRequestRefundForPurchaseArgs = {
  input: RequestRefundForPurchaseInput;
};


export type MutationResendPurchaseConfirmationEmailArgs = {
  input: ResendPurchaseConfirmationEmailInput;
};


export type MutationSendEmailToUsersArgs = {
  input: SendEmailToUsersMutationInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdatePerformerArgs = {
  input: UpdatePerformerInput;
};


export type MutationUpdatePromoCodeArgs = {
  input: UpdatePromoCodeInput;
};


export type MutationUpdateStageArgs = {
  input: UpdateStageInput;
};


export type MutationUpdateTicketPoolArgs = {
  input: UpdateTicketPoolInput;
};


export type MutationUpdateTicketTypeArgs = {
  input: UpdateTicketTypeInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateVenueArgs = {
  input: UpdateVenueInput;
};

export type MutationResult = {
  __typename?: 'MutationResult';
  created?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['ID']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type NodeQuery = {
  __typename?: 'NodeQuery';
  /** Get event by id */
  event?: Maybe<Event>;
  /** Get performer by id */
  performer?: Maybe<Performer>;
  /** Get stage by id */
  stage?: Maybe<Stage>;
  /** Get venue by id */
  venue?: Maybe<Venue>;
};


export type NodeQueryEventArgs = {
  id: Scalars['ID'];
  promoCode?: InputMaybe<Scalars['String']>;
};


export type NodeQueryPerformerArgs = {
  id: Scalars['ID'];
};


export type NodeQueryStageArgs = {
  id: Scalars['ID'];
};


export type NodeQueryVenueArgs = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  cardBrand: Scalars['String'];
  cardNumberLast4: Scalars['String'];
  expMonth: Scalars['Long'];
  expYear: Scalars['Long'];
  id: Scalars['String'];
};

export type PercentBasedFeeStrategy = {
  __typename?: 'PercentBasedFeeStrategy';
  percent: Scalars['BigDecimal'];
};

export type Performer = Node & {
  __typename?: 'Performer';
  description: Scalars['String'];
  events: EventConnection;
  facebookUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imgUrl: Scalars['String'];
  instagramUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  spotifyUrl?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};


export type PerformerEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  dateTimeGt?: InputMaybe<Scalars['Long']>;
  dateTimeLt?: InputMaybe<Scalars['Long']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  stageId?: InputMaybe<Scalars['ID']>;
  venueId?: InputMaybe<Scalars['ID']>;
};

export type PerformerConnection = Connection & {
  __typename?: 'PerformerConnection';
  edges: Array<PerformerEdge>;
  pageInfo: PageInfo;
};

export type PerformerEdge = Edge & {
  __typename?: 'PerformerEdge';
  cursor: Scalars['String'];
  node: Performer;
};

export type Price = {
  __typename?: 'Price';
  base: Money;
  fee: Money;
  total: Money;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  revealsTicketTypes: Array<TicketType>;
  validFrom: Scalars['DateTime'];
  validThrough: Scalars['DateTime'];
};

export type Purchase = Node & {
  __typename?: 'Purchase';
  cancelled: Scalars['Boolean'];
  event: Event;
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  items: Array<PurchaseItem>;
  purchasedAt: Scalars['DateTime'];
  purchasedBy: User;
  purchasedById: Scalars['ID'];
  ticketsPdfUrl: Scalars['String'];
};

export type PurchaseConnection = Connection & {
  __typename?: 'PurchaseConnection';
  edges: Array<PurchaseEdge>;
  pageInfo: PageInfo;
};

export type PurchaseEdge = Edge & {
  __typename?: 'PurchaseEdge';
  cursor: Scalars['String'];
  node: Purchase;
};

export type PurchaseItem = {
  __typename?: 'PurchaseItem';
  event: Event;
  eventId: Scalars['ID'];
  faceValue: Money;
  id: Scalars['String'];
  paidFee: Money;
  paidTax: Money;
  paidTotal: Money;
  refundedAmount?: Maybe<Money>;
  ticket?: Maybe<Ticket>;
  ticketId?: Maybe<Scalars['ID']>;
  ticketType: TicketType;
  ticketTypeId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  admin: AdminQuery;
  /** Get event by id */
  event?: Maybe<Event>;
  featured: FeaturedQuery;
  me?: Maybe<User>;
  node: NodeQuery;
  /** Get performer by id */
  performer?: Maybe<Performer>;
  search: SearchQuery;
  someday: Scalars['String'];
  /** Get stage by id */
  stage?: Maybe<Stage>;
  /** Get venue by id */
  venue?: Maybe<Venue>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
  promoCode?: InputMaybe<Scalars['String']>;
};


export type QueryPerformerArgs = {
  id: Scalars['ID'];
};


export type QueryStageArgs = {
  id: Scalars['ID'];
};


export type QueryVenueArgs = {
  id: Scalars['ID'];
};

export type RefundForPurchaseItemsMutationInput = {
  purchaseId: Scalars['ID'];
  purchaseItemIds: Array<Scalars['String']>;
  refundFee: Scalars['Boolean'];
};

export type RequestRefundForPurchaseInput = {
  purchaseId: Scalars['ID'];
  reason: Scalars['String'];
};

export type ResendPurchaseConfirmationEmailInput = {
  attachTicketsPdf?: InputMaybe<Scalars['Boolean']>;
  purchaseId: Scalars['ID'];
};

export type SearchQuery = {
  __typename?: 'SearchQuery';
  /** Query events */
  events?: Maybe<EventConnection>;
  /** Query performers */
  performers?: Maybe<PerformerConnection>;
  /** Query stages */
  stages?: Maybe<StageConnection>;
  /** Query venues */
  venues?: Maybe<VenueConnection>;
};


export type SearchQueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  dateTimeGt?: InputMaybe<Scalars['Long']>;
  dateTimeLt?: InputMaybe<Scalars['Long']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  performerId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  stageId?: InputMaybe<Scalars['ID']>;
  venueId?: InputMaybe<Scalars['ID']>;
};


export type SearchQueryPerformersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};


export type SearchQueryStagesArgs = {
  activated?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  sort?: InputMaybe<Sort>;
  venueId?: InputMaybe<Scalars['ID']>;
};


export type SearchQueryVenuesArgs = {
  activated?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};

export type SendEmailToUsersMutationInput = {
  body: Scalars['String'];
  eventId: Scalars['ID'];
  subject: Scalars['String'];
};

export type SignUpInput = {
  consentedToMarketing?: InputMaybe<Scalars['Boolean']>;
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  termsOfServiceVersion?: InputMaybe<Scalars['Int']>;
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Stage = Node & {
  __typename?: 'Stage';
  activated: Scalars['Boolean'];
  events: EventConnection;
  feeStrategy: FeeStrategy;
  id: Scalars['ID'];
  name: Scalars['String'];
  venue: Venue;
  venueId: Scalars['ID'];
};


export type StageEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  dateTimeGt?: InputMaybe<Scalars['Long']>;
  dateTimeLt?: InputMaybe<Scalars['Long']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  performerId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  venueId?: InputMaybe<Scalars['ID']>;
};

export type StageConnection = Connection & {
  __typename?: 'StageConnection';
  edges: Array<StageEdge>;
  pageInfo: PageInfo;
};

export type StageEdge = Edge & {
  __typename?: 'StageEdge';
  cursor: Scalars['String'];
  node: Stage;
};

export type TaxRate = Node & {
  __typename?: 'TaxRate';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['BigDecimal'];
  venues: VenueConnection;
};


export type TaxRateVenuesArgs = {
  activated?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};

export type TaxRateConnection = Connection & {
  __typename?: 'TaxRateConnection';
  edges: Array<TaxRateEdge>;
  pageInfo: PageInfo;
};

export type TaxRateEdge = Edge & {
  __typename?: 'TaxRateEdge';
  cursor: Scalars['String'];
  node: TaxRate;
};

export type Ticket = Node & {
  __typename?: 'Ticket';
  event: Event;
  eventId: Scalars['ID'];
  finalPrice: Money;
  id: Scalars['ID'];
  purchase: Purchase;
  purchaseId: Scalars['ID'];
  purchasedBy: User;
  purchasedById: Scalars['ID'];
  qrcode: Scalars['String'];
};

export type TicketConnection = Connection & {
  __typename?: 'TicketConnection';
  edges: Array<TicketEdge>;
  pageInfo: PageInfo;
};

export type TicketEdge = Edge & {
  __typename?: 'TicketEdge';
  cursor: Scalars['String'];
  node: Ticket;
};

export type TicketPool = {
  __typename?: 'TicketPool';
  id: Scalars['String'];
  name: Scalars['String'];
  ticketLeftCount: Scalars['Int'];
  ticketTypes: Array<TicketType>;
};

export type TicketType = {
  __typename?: 'TicketType';
  basePrice: Money;
  fee: Money;
  helperText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  maxPerCheckoutCount: Scalars['Int'];
  name: Scalars['String'];
  price: Price;
  revealedByPromoCode: Scalars['Boolean'];
  saleEndsAt: Scalars['DateTime'];
  saleStartsAt: Scalars['DateTime'];
  ticketLeftCount: Scalars['Int'];
  totalPrice: Money;
};

export type UpdateEventInput = {
  audienceAllowedMinimumAge?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  endDateTime?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
  featuredLevel?: InputMaybe<Scalars['Int']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  lineup?: InputMaybe<LineupInput>;
  startDateTime?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdatePerformerInput = {
  description: Scalars['String'];
  facebookUrl?: InputMaybe<Scalars['String']>;
  imgUrl: Scalars['String'];
  instagramUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  performerId: Scalars['ID'];
  spotifyUrl?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type UpdatePromoCodeInput = {
  description?: InputMaybe<Scalars['String']>;
  eventId: Scalars['ID'];
  promoCodeId: Scalars['String'];
  revealsTicketTypes?: InputMaybe<Array<Scalars['Long']>>;
  validFrom?: InputMaybe<Scalars['DateTime']>;
  validThrough?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateStageInput = {
  feeStrategy: FeeStrategyInput;
  name?: InputMaybe<Scalars['String']>;
  stageId: Scalars['ID'];
};

export type UpdateTicketPoolInput = {
  eventId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  ticketCount?: InputMaybe<Scalars['Int']>;
  ticketPoolId: Scalars['String'];
};

export type UpdateTicketTypeInput = {
  eventId: Scalars['ID'];
  helperText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  promoCodes?: InputMaybe<Array<Scalars['String']>>;
  saleEndsAt?: InputMaybe<Scalars['String']>;
  saleStartsAt?: InputMaybe<Scalars['String']>;
  ticketTypeId: Scalars['String'];
};

export type UpdateUserProfileInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateVenueInput = {
  address?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  imgUrl?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
  mapUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  taxRateId?: InputMaybe<Scalars['ID']>;
  venueId: Scalars['ID'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  /** Get my checkout by id */
  checkout?: Maybe<EmbeddedCheckout>;
  consentedToMarketing: Scalars['Boolean'];
  dateOfBirth: Scalars['Date'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  intercomHmac: Scalars['String'];
  lastName: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  phoneNumber: Scalars['String'];
  /** Get my purchase by id */
  purchase?: Maybe<Purchase>;
  /** Query my purchases */
  purchases: PurchaseConnection;
  role: Scalars['String'];
  signedUpAt: Scalars['DateTime'];
  /** Query my tickets */
  tickets: TicketConnection;
};


export type UserCheckoutArgs = {
  id: Scalars['ID'];
};


export type UserPurchaseArgs = {
  id: Scalars['ID'];
};


export type UserPurchasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  checkoutId?: InputMaybe<Scalars['ID']>;
  eventEndDateTimeGt?: InputMaybe<Scalars['DateTime']>;
  eventEndDateTimeLe?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  sort?: InputMaybe<Sort>;
  sortBy?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type UserTicketsArgs = {
  after?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  purchaseId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<Sort>;
  ticketTypeId?: InputMaybe<Scalars['String']>;
};

export type UserConnection = Connection & {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserEdge = Edge & {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type Venue = Node & {
  __typename?: 'Venue';
  activated: Scalars['Boolean'];
  address: Scalars['String'];
  events: EventConnection;
  facebookUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imgUrl: Scalars['String'];
  instagramUrl?: Maybe<Scalars['String']>;
  location: Location;
  mapUrl: Scalars['String'];
  name: Scalars['String'];
  stages: StageConnection;
  taxRate: TaxRate;
  taxRateId: Scalars['ID'];
  timezone: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
};


export type VenueEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  dateTimeGt?: InputMaybe<Scalars['Long']>;
  dateTimeLt?: InputMaybe<Scalars['Long']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  performerId?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  stageId?: InputMaybe<Scalars['ID']>;
};


export type VenueStagesArgs = {
  activated?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  sort?: InputMaybe<Sort>;
};

export type VenueConnection = Connection & {
  __typename?: 'VenueConnection';
  edges: Array<VenueEdge>;
  pageInfo: PageInfo;
};

export type VenueEdge = Edge & {
  __typename?: 'VenueEdge';
  cursor: Scalars['String'];
  node: Venue;
};

export type ArtistQueryVariables = Exact<{
  id: Scalars['ID'];
  datetimeGt: Scalars['Long'];
}>;


export type ArtistQuery = { __typename?: 'Query', performer?: { __typename?: 'Performer', id: string, imgUrl: string, name: string, facebookUrl?: string | null, instagramUrl?: string | null, websiteUrl?: string | null, spotifyUrl?: string | null, description: string, events: { __typename?: 'EventConnection', edges: Array<{ __typename?: 'EventEdge', node: { __typename?: 'Event', id: string, title: string, startDateTime: any, endDateTime: any, imgUrl: string, isCancelled: boolean, venue: { __typename?: 'Venue', name: string, timezone: string } } }> } } | null };

export type CheckoutQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckoutQuery = { __typename?: 'Query', me?: { __typename?: 'User', checkout?: { __typename?: 'EmbeddedCheckout', id: string, lineItems: Array<{ __typename?: 'EmbeddedCheckoutLineItem', description: string, id: string, quantity: any, price: { __typename?: 'Price', base: { __typename?: 'Money', amount: string, currency: string }, fee: { __typename?: 'Money', amount: string, currency: string }, total: { __typename?: 'Money', amount: string, currency: string } } }>, taxRate: { __typename?: 'TaxRate', id: string, name: string, value: any } } | null, paymentMethod?: { __typename?: 'PaymentMethod', id: string, cardBrand: string, cardNumberLast4: string, expMonth: any, expYear: any } | null } | null };

export type CompleteEmbeddedPurchaseMutationVariables = Exact<{
  input: CompleteEmbeddedPaymentInput;
}>;


export type CompleteEmbeddedPurchaseMutation = { __typename?: 'Mutation', completeEmbeddedPurchase: { __typename?: 'MutationResult', created?: string | null, error?: string | null } };

export type CreateEmbeddedCheckoutMutationVariables = Exact<{
  input: CreateEmbeddedCheckoutInput;
}>;


export type CreateEmbeddedCheckoutMutation = { __typename?: 'Mutation', createEmbeddedCheckout: { __typename?: 'EmbeddedCheckout', id: string } };

export type CreateSetupIntentMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateSetupIntentMutation = { __typename?: 'Mutation', createSetupIntent: { __typename?: 'CreateSetupIntentResult', clientSecret: string } };

export type EventQueryVariables = Exact<{
  id: Scalars['ID'];
  promoCode?: InputMaybe<Scalars['String']>;
}>;


export type EventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', id: string, imgUrl: string, title: string, startDateTime: any, endDateTime: any, description: string, audienceMinimumAge: number, isCancelled: boolean, venue: { __typename?: 'Venue', id: string, address: string, imgUrl: string, mapUrl: string, name: string, timezone: string, websiteUrl?: string | null, taxRate: { __typename?: 'TaxRate', id: string, name: string, value: any } }, ticketTypes: Array<{ __typename?: 'TicketType', id: string, maxPerCheckoutCount: number, ticketLeftCount: number, name: string, saleStartsAt: any, saleEndsAt: any, revealedByPromoCode: boolean, helperText?: string | null, price: { __typename?: 'Price', base: { __typename?: 'Money', amount: string, currency: string }, fee: { __typename?: 'Money', amount: string, currency: string }, total: { __typename?: 'Money', amount: string, currency: string } } }>, lineup: { __typename?: 'Lineup', entries: Array<{ __typename?: 'LineupEntry', id: string, performer: { __typename?: 'Performer', id: string, imgUrl: string, name: string } }> } } | null };

export type FeaturedEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturedEventsQuery = { __typename?: 'Query', featured: { __typename?: 'FeaturedQuery', events?: Array<{ __typename?: 'Event', id: string, imgUrl: string, title: string, startDateTime: any, isCancelled: boolean, venue: { __typename?: 'Venue', name: string, timezone: string } }> | null } };

export type MyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MyInfoQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, role: string, firstName: string, lastName: string, dateOfBirth: any, emailAddress: string, phoneNumber: string, signedUpAt: any, intercomHmac: string, consentedToMarketing: boolean } | null };

export type MyPurchaseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MyPurchaseQuery = { __typename?: 'Query', me?: { __typename?: 'User', purchase?: { __typename?: 'Purchase', id: string, cancelled: boolean, ticketsPdfUrl: string, items: Array<{ __typename?: 'PurchaseItem', ticket?: { __typename?: 'Ticket', id: string, qrcode: string } | null, refundedAmount?: { __typename?: 'Money', currency: string, amount: string } | null, ticketType: { __typename?: 'TicketType', id: string, name: string, price: { __typename?: 'Price', base: { __typename?: 'Money', currency: string, amount: string }, fee: { __typename?: 'Money', currency: string, amount: string }, total: { __typename?: 'Money', currency: string, amount: string } } } }>, event: { __typename?: 'Event', id: string, imgUrl: string, title: string, startDateTime: any, endDateTime: any, isCancelled: boolean, venue: { __typename?: 'Venue', name: string, address: string, timezone: string, taxRate: { __typename?: 'TaxRate', id: string, name: string, value: any } } }, purchasedBy: { __typename?: 'User', firstName: string, lastName: string } } | null } | null };

export type MyPurchasesQueryVariables = Exact<{
  now: Scalars['DateTime'];
  upcomingEventsEndCursor?: InputMaybe<Scalars['String']>;
  pastEventsEndCursor?: InputMaybe<Scalars['String']>;
}>;


export type MyPurchasesQuery = { __typename?: 'Query', me?: { __typename?: 'User', upcomingEvents: { __typename?: 'PurchaseConnection', edges: Array<{ __typename?: 'PurchaseEdge', node: { __typename?: 'Purchase', id: string, event: { __typename?: 'Event', id: string, title: string, startDateTime: any, endDateTime: any, imgUrl: string, isCancelled: boolean, venue: { __typename?: 'Venue', id: string, name: string, timezone: string } } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } }, pastEvents: { __typename?: 'PurchaseConnection', edges: Array<{ __typename?: 'PurchaseEdge', node: { __typename?: 'Purchase', id: string, event: { __typename?: 'Event', id: string, title: string, startDateTime: any, endDateTime: any, imgUrl: string, isCancelled: boolean, venue: { __typename?: 'Venue', id: string, name: string, timezone: string } } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } } | null };

export type RequestRefundForPurchaseMutationVariables = Exact<{
  input: RequestRefundForPurchaseInput;
}>;


export type RequestRefundForPurchaseMutation = { __typename?: 'Mutation', requestRefundForPurchase: { __typename?: 'MutationResult', error?: string | null } };

export type ResendEmailVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendEmailVerificationMutation = { __typename?: 'Mutation', resendEmailVerification: { __typename?: 'MutationResult', error?: string | null } };

export type SearchQQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  datetimeGt: Scalars['Long'];
}>;


export type SearchQQuery = { __typename?: 'Query', search: { __typename?: 'SearchQuery', events?: { __typename?: 'EventConnection', edges: Array<{ __typename?: 'EventEdge', node: { __typename?: 'Event', title: string, startDateTime: any, imgUrl: string, id: string, isCancelled: boolean, venue: { __typename?: 'Venue', name: string, timezone: string } } }> } | null, venues?: { __typename?: 'VenueConnection', edges: Array<{ __typename?: 'VenueEdge', node: { __typename?: 'Venue', name: string, address: string, imgUrl: string, id: string } }> } | null, performers?: { __typename?: 'PerformerConnection', edges: Array<{ __typename?: 'PerformerEdge', node: { __typename?: 'Performer', name: string, imgUrl: string, description: string, id: string } }> } | null } };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'MutationResult', error?: string | null } };

export type SiteMapQueryVariables = Exact<{
  now: Scalars['Long'];
}>;


export type SiteMapQuery = { __typename?: 'Query', search: { __typename?: 'SearchQuery', events?: { __typename?: 'EventConnection', edges: Array<{ __typename?: 'EventEdge', node: { __typename?: 'Event', id: string } }> } | null, venues?: { __typename?: 'VenueConnection', edges: Array<{ __typename?: 'VenueEdge', node: { __typename?: 'Venue', id: string } }> } | null } };

export type UpdateUserProfileMutationVariables = Exact<{
  updateUserProfileInput: UpdateUserProfileInput;
  consentedToMarketing: Scalars['Boolean'];
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile: { __typename?: 'MutationResult', error?: string | null }, consentToMarketing: { __typename?: 'MutationResult', error?: string | null } };

export type VenueQueryVariables = Exact<{
  id: Scalars['ID'];
  datetimeGt: Scalars['Long'];
}>;


export type VenueQuery = { __typename?: 'Query', venue?: { __typename?: 'Venue', id: string, imgUrl: string, name: string, address: string, websiteUrl?: string | null, facebookUrl?: string | null, instagramUrl?: string | null, timezone: string, events: { __typename?: 'EventConnection', edges: Array<{ __typename?: 'EventEdge', node: { __typename?: 'Event', id: string, title: string, startDateTime: any, endDateTime: any, imgUrl: string, isCancelled: boolean } }> } } | null };


export const ArtistDocument = gql`
    query Artist($id: ID!, $datetimeGt: Long!) {
  performer(id: $id) {
    id
    imgUrl
    name
    facebookUrl
    instagramUrl
    websiteUrl
    spotifyUrl
    description
    events(dateTimeGt: $datetimeGt, sort: ASC, isCancelled: false) {
      edges {
        node {
          id
          title
          startDateTime
          endDateTime
          imgUrl
          venue {
            name
            timezone
          }
          isCancelled
        }
      }
    }
  }
}
    `;

/**
 * __useArtistQuery__
 *
 * To run a query within a React component, call `useArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistQuery({
 *   variables: {
 *      id: // value for 'id'
 *      datetimeGt: // value for 'datetimeGt'
 *   },
 * });
 */
export function useArtistQuery(baseOptions: Apollo.QueryHookOptions<ArtistQuery, ArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArtistQuery, ArtistQueryVariables>(ArtistDocument, options);
      }
export function useArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArtistQuery, ArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArtistQuery, ArtistQueryVariables>(ArtistDocument, options);
        }
export type ArtistQueryHookResult = ReturnType<typeof useArtistQuery>;
export type ArtistLazyQueryHookResult = ReturnType<typeof useArtistLazyQuery>;
export type ArtistQueryResult = Apollo.QueryResult<ArtistQuery, ArtistQueryVariables>;
export const CheckoutDocument = gql`
    query Checkout($id: ID!) {
  me {
    checkout(id: $id) {
      id
      lineItems {
        description
        id
        price {
          base {
            amount
            currency
          }
          fee {
            amount
            currency
          }
          total {
            amount
            currency
          }
        }
        quantity
      }
      taxRate {
        id
        name
        value
      }
    }
    paymentMethod {
      id
      cardBrand
      cardNumberLast4
      expMonth
      expYear
    }
  }
}
    `;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const CompleteEmbeddedPurchaseDocument = gql`
    mutation CompleteEmbeddedPurchase($input: CompleteEmbeddedPaymentInput!) {
  completeEmbeddedPurchase(input: $input) {
    created
    error
  }
}
    `;
export type CompleteEmbeddedPurchaseMutationFn = Apollo.MutationFunction<CompleteEmbeddedPurchaseMutation, CompleteEmbeddedPurchaseMutationVariables>;

/**
 * __useCompleteEmbeddedPurchaseMutation__
 *
 * To run a mutation, you first call `useCompleteEmbeddedPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEmbeddedPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEmbeddedPurchaseMutation, { data, loading, error }] = useCompleteEmbeddedPurchaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteEmbeddedPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<CompleteEmbeddedPurchaseMutation, CompleteEmbeddedPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteEmbeddedPurchaseMutation, CompleteEmbeddedPurchaseMutationVariables>(CompleteEmbeddedPurchaseDocument, options);
      }
export type CompleteEmbeddedPurchaseMutationHookResult = ReturnType<typeof useCompleteEmbeddedPurchaseMutation>;
export type CompleteEmbeddedPurchaseMutationResult = Apollo.MutationResult<CompleteEmbeddedPurchaseMutation>;
export type CompleteEmbeddedPurchaseMutationOptions = Apollo.BaseMutationOptions<CompleteEmbeddedPurchaseMutation, CompleteEmbeddedPurchaseMutationVariables>;
export const CreateEmbeddedCheckoutDocument = gql`
    mutation CreateEmbeddedCheckout($input: CreateEmbeddedCheckoutInput!) {
  createEmbeddedCheckout(input: $input) {
    id
  }
}
    `;
export type CreateEmbeddedCheckoutMutationFn = Apollo.MutationFunction<CreateEmbeddedCheckoutMutation, CreateEmbeddedCheckoutMutationVariables>;

/**
 * __useCreateEmbeddedCheckoutMutation__
 *
 * To run a mutation, you first call `useCreateEmbeddedCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmbeddedCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmbeddedCheckoutMutation, { data, loading, error }] = useCreateEmbeddedCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmbeddedCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmbeddedCheckoutMutation, CreateEmbeddedCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmbeddedCheckoutMutation, CreateEmbeddedCheckoutMutationVariables>(CreateEmbeddedCheckoutDocument, options);
      }
export type CreateEmbeddedCheckoutMutationHookResult = ReturnType<typeof useCreateEmbeddedCheckoutMutation>;
export type CreateEmbeddedCheckoutMutationResult = Apollo.MutationResult<CreateEmbeddedCheckoutMutation>;
export type CreateEmbeddedCheckoutMutationOptions = Apollo.BaseMutationOptions<CreateEmbeddedCheckoutMutation, CreateEmbeddedCheckoutMutationVariables>;
export const CreateSetupIntentDocument = gql`
    mutation CreateSetupIntent {
  createSetupIntent {
    clientSecret
  }
}
    `;
export type CreateSetupIntentMutationFn = Apollo.MutationFunction<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;

/**
 * __useCreateSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreateSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetupIntentMutation, { data, loading, error }] = useCreateSetupIntentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSetupIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>(CreateSetupIntentDocument, options);
      }
export type CreateSetupIntentMutationHookResult = ReturnType<typeof useCreateSetupIntentMutation>;
export type CreateSetupIntentMutationResult = Apollo.MutationResult<CreateSetupIntentMutation>;
export type CreateSetupIntentMutationOptions = Apollo.BaseMutationOptions<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>;
export const EventDocument = gql`
    query Event($id: ID!, $promoCode: String) {
  event(id: $id, promoCode: $promoCode) {
    id
    imgUrl
    title
    startDateTime
    endDateTime
    description
    audienceMinimumAge
    isCancelled
    venue {
      id
      address
      imgUrl
      mapUrl
      name
      timezone
      websiteUrl
      taxRate {
        id
        name
        value
      }
    }
    ticketTypes {
      id
      maxPerCheckoutCount
      ticketLeftCount
      name
      price {
        base {
          amount
          currency
        }
        fee {
          amount
          currency
        }
        total {
          amount
          currency
        }
      }
      saleStartsAt
      saleEndsAt
      revealedByPromoCode
      helperText
    }
    lineup {
      entries {
        id
        performer {
          id
          imgUrl
          name
        }
      }
    }
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const FeaturedEventsDocument = gql`
    query FeaturedEvents {
  featured {
    events {
      id
      imgUrl
      title
      startDateTime
      venue {
        name
        timezone
      }
      isCancelled
    }
  }
}
    `;

/**
 * __useFeaturedEventsQuery__
 *
 * To run a query within a React component, call `useFeaturedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedEventsQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedEventsQuery, FeaturedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedEventsQuery, FeaturedEventsQueryVariables>(FeaturedEventsDocument, options);
      }
export function useFeaturedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedEventsQuery, FeaturedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedEventsQuery, FeaturedEventsQueryVariables>(FeaturedEventsDocument, options);
        }
export type FeaturedEventsQueryHookResult = ReturnType<typeof useFeaturedEventsQuery>;
export type FeaturedEventsLazyQueryHookResult = ReturnType<typeof useFeaturedEventsLazyQuery>;
export type FeaturedEventsQueryResult = Apollo.QueryResult<FeaturedEventsQuery, FeaturedEventsQueryVariables>;
export const MyInfoDocument = gql`
    query MyInfo {
  me {
    id
    role
    firstName
    lastName
    dateOfBirth
    emailAddress
    phoneNumber
    signedUpAt
    intercomHmac
    consentedToMarketing
  }
}
    `;

/**
 * __useMyInfoQuery__
 *
 * To run a query within a React component, call `useMyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyInfoQuery(baseOptions?: Apollo.QueryHookOptions<MyInfoQuery, MyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyInfoQuery, MyInfoQueryVariables>(MyInfoDocument, options);
      }
export function useMyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyInfoQuery, MyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyInfoQuery, MyInfoQueryVariables>(MyInfoDocument, options);
        }
export type MyInfoQueryHookResult = ReturnType<typeof useMyInfoQuery>;
export type MyInfoLazyQueryHookResult = ReturnType<typeof useMyInfoLazyQuery>;
export type MyInfoQueryResult = Apollo.QueryResult<MyInfoQuery, MyInfoQueryVariables>;
export const MyPurchaseDocument = gql`
    query MyPurchase($id: ID!) {
  me {
    purchase(id: $id) {
      id
      cancelled
      ticketsPdfUrl
      items {
        ticket {
          id
          qrcode
        }
        refundedAmount {
          currency
          amount
        }
        ticketType {
          id
          price {
            base {
              currency
              amount
            }
            fee {
              currency
              amount
            }
            total {
              currency
              amount
            }
          }
          name
        }
      }
      event {
        id
        imgUrl
        title
        venue {
          name
          address
          timezone
          taxRate {
            id
            name
            value
          }
        }
        startDateTime
        endDateTime
        isCancelled
      }
      purchasedBy {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useMyPurchaseQuery__
 *
 * To run a query within a React component, call `useMyPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPurchaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyPurchaseQuery(baseOptions: Apollo.QueryHookOptions<MyPurchaseQuery, MyPurchaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyPurchaseQuery, MyPurchaseQueryVariables>(MyPurchaseDocument, options);
      }
export function useMyPurchaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyPurchaseQuery, MyPurchaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyPurchaseQuery, MyPurchaseQueryVariables>(MyPurchaseDocument, options);
        }
export type MyPurchaseQueryHookResult = ReturnType<typeof useMyPurchaseQuery>;
export type MyPurchaseLazyQueryHookResult = ReturnType<typeof useMyPurchaseLazyQuery>;
export type MyPurchaseQueryResult = Apollo.QueryResult<MyPurchaseQuery, MyPurchaseQueryVariables>;
export const MyPurchasesDocument = gql`
    query MyPurchases($now: DateTime!, $upcomingEventsEndCursor: String, $pastEventsEndCursor: String) {
  me {
    upcomingEvents: purchases(
      eventEndDateTimeGt: $now
      sort: ASC
      sortBy: "event.start_date_time"
      after: $upcomingEventsEndCursor
    ) {
      edges {
        node {
          id
          event {
            id
            title
            venue {
              id
              name
              timezone
            }
            startDateTime
            endDateTime
            imgUrl
            isCancelled
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    pastEvents: purchases(
      eventEndDateTimeLe: $now
      sort: DESC
      sortBy: "event.start_date_time"
      after: $pastEventsEndCursor
    ) {
      edges {
        node {
          id
          event {
            id
            title
            venue {
              id
              name
              timezone
            }
            startDateTime
            endDateTime
            imgUrl
            isCancelled
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
    `;

/**
 * __useMyPurchasesQuery__
 *
 * To run a query within a React component, call `useMyPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPurchasesQuery({
 *   variables: {
 *      now: // value for 'now'
 *      upcomingEventsEndCursor: // value for 'upcomingEventsEndCursor'
 *      pastEventsEndCursor: // value for 'pastEventsEndCursor'
 *   },
 * });
 */
export function useMyPurchasesQuery(baseOptions: Apollo.QueryHookOptions<MyPurchasesQuery, MyPurchasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyPurchasesQuery, MyPurchasesQueryVariables>(MyPurchasesDocument, options);
      }
export function useMyPurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyPurchasesQuery, MyPurchasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyPurchasesQuery, MyPurchasesQueryVariables>(MyPurchasesDocument, options);
        }
export type MyPurchasesQueryHookResult = ReturnType<typeof useMyPurchasesQuery>;
export type MyPurchasesLazyQueryHookResult = ReturnType<typeof useMyPurchasesLazyQuery>;
export type MyPurchasesQueryResult = Apollo.QueryResult<MyPurchasesQuery, MyPurchasesQueryVariables>;
export const RequestRefundForPurchaseDocument = gql`
    mutation RequestRefundForPurchase($input: RequestRefundForPurchaseInput!) {
  requestRefundForPurchase(input: $input) {
    error
  }
}
    `;
export type RequestRefundForPurchaseMutationFn = Apollo.MutationFunction<RequestRefundForPurchaseMutation, RequestRefundForPurchaseMutationVariables>;

/**
 * __useRequestRefundForPurchaseMutation__
 *
 * To run a mutation, you first call `useRequestRefundForPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRefundForPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRefundForPurchaseMutation, { data, loading, error }] = useRequestRefundForPurchaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestRefundForPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<RequestRefundForPurchaseMutation, RequestRefundForPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestRefundForPurchaseMutation, RequestRefundForPurchaseMutationVariables>(RequestRefundForPurchaseDocument, options);
      }
export type RequestRefundForPurchaseMutationHookResult = ReturnType<typeof useRequestRefundForPurchaseMutation>;
export type RequestRefundForPurchaseMutationResult = Apollo.MutationResult<RequestRefundForPurchaseMutation>;
export type RequestRefundForPurchaseMutationOptions = Apollo.BaseMutationOptions<RequestRefundForPurchaseMutation, RequestRefundForPurchaseMutationVariables>;
export const ResendEmailVerificationDocument = gql`
    mutation ResendEmailVerification {
  resendEmailVerification {
    error
  }
}
    `;
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(ResendEmailVerificationDocument, options);
      }
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationResult = Apollo.MutationResult<ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;
export const SearchQDocument = gql`
    query SearchQ($searchTerm: String!, $datetimeGt: Long!) {
  search {
    events(
      searchTerm: $searchTerm
      dateTimeGt: $datetimeGt
      sort: ASC
      isCancelled: false
    ) {
      edges {
        node {
          venue {
            name
            timezone
          }
          title
          startDateTime
          imgUrl
          id
          isCancelled
        }
      }
    }
    venues(searchTerm: $searchTerm) {
      edges {
        node {
          name
          address
          imgUrl
          id
        }
      }
    }
    performers(searchTerm: $searchTerm) {
      edges {
        node {
          name
          imgUrl
          description
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSearchQQuery__
 *
 * To run a query within a React component, call `useSearchQQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      datetimeGt: // value for 'datetimeGt'
 *   },
 * });
 */
export function useSearchQQuery(baseOptions: Apollo.QueryHookOptions<SearchQQuery, SearchQQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQQuery, SearchQQueryVariables>(SearchQDocument, options);
      }
export function useSearchQLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQQuery, SearchQQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQQuery, SearchQQueryVariables>(SearchQDocument, options);
        }
export type SearchQQueryHookResult = ReturnType<typeof useSearchQQuery>;
export type SearchQLazyQueryHookResult = ReturnType<typeof useSearchQLazyQuery>;
export type SearchQQueryResult = Apollo.QueryResult<SearchQQuery, SearchQQueryVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    error
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SiteMapDocument = gql`
    query SiteMap($now: Long!) {
  search {
    events(dateTimeGt: $now, sort: ASC, isCancelled: false, first: 1000) {
      edges {
        node {
          id
        }
      }
    }
    venues(first: 1000, activated: true) {
      edges {
        node {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSiteMapQuery__
 *
 * To run a query within a React component, call `useSiteMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMapQuery({
 *   variables: {
 *      now: // value for 'now'
 *   },
 * });
 */
export function useSiteMapQuery(baseOptions: Apollo.QueryHookOptions<SiteMapQuery, SiteMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMapQuery, SiteMapQueryVariables>(SiteMapDocument, options);
      }
export function useSiteMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMapQuery, SiteMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMapQuery, SiteMapQueryVariables>(SiteMapDocument, options);
        }
export type SiteMapQueryHookResult = ReturnType<typeof useSiteMapQuery>;
export type SiteMapLazyQueryHookResult = ReturnType<typeof useSiteMapLazyQuery>;
export type SiteMapQueryResult = Apollo.QueryResult<SiteMapQuery, SiteMapQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($updateUserProfileInput: UpdateUserProfileInput!, $consentedToMarketing: Boolean!) {
  updateUserProfile(input: $updateUserProfileInput) {
    error
  }
  consentToMarketing(input: {consented: $consentedToMarketing}) {
    error
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      updateUserProfileInput: // value for 'updateUserProfileInput'
 *      consentedToMarketing: // value for 'consentedToMarketing'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const VenueDocument = gql`
    query Venue($id: ID!, $datetimeGt: Long!) {
  venue(id: $id) {
    id
    imgUrl
    name
    address
    websiteUrl
    facebookUrl
    instagramUrl
    timezone
    events(dateTimeGt: $datetimeGt, sort: ASC, isCancelled: false) {
      edges {
        node {
          id
          title
          startDateTime
          endDateTime
          imgUrl
          isCancelled
        }
      }
    }
  }
}
    `;

/**
 * __useVenueQuery__
 *
 * To run a query within a React component, call `useVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *      datetimeGt: // value for 'datetimeGt'
 *   },
 * });
 */
export function useVenueQuery(baseOptions: Apollo.QueryHookOptions<VenueQuery, VenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VenueQuery, VenueQueryVariables>(VenueDocument, options);
      }
export function useVenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenueQuery, VenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VenueQuery, VenueQueryVariables>(VenueDocument, options);
        }
export type VenueQueryHookResult = ReturnType<typeof useVenueQuery>;
export type VenueLazyQueryHookResult = ReturnType<typeof useVenueLazyQuery>;
export type VenueQueryResult = Apollo.QueryResult<VenueQuery, VenueQueryVariables>;