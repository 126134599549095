import MobileDetect from 'mobile-detect';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import AgentContext from '@/contexts/AgentContext';

export type Viewport = 'mobile' | 'tablet' | 'desktop';

function useViewport(): Viewport {
  const agentContext = useContext(AgentContext);
  const md = new MobileDetect(agentContext.userAgent);
  const isDesktop = useMediaQuery({
    query: `(min-width: 1224px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: 640px)`,
  });
  const initialViewPort = md.tablet()
    ? `tablet`
    : md.mobile()
    ? `mobile`
    : `desktop`;

  const [viewport, setViewport] = useState<Viewport>(initialViewPort);

  useEffect(() => {
    setViewport(isDesktop ? `desktop` : isTablet ? `tablet` : `mobile`);
  }, [isDesktop, isTablet]);

  return viewport;
}

export default useViewport;
